
import { defineComponent, onMounted, ref, toRefs, watch } from "vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import Swal from "sweetalert2";

export default defineComponent({
  name: "copy-classes-modal",
  props:{
    semesterId: Number,
    semesterName : String
  },
  setup(props) {
    const newSemesterData = ref({id: 0, name:'', code:'', period: [], start_date:null, end_date:null});
    const { semesterId } = toRefs(props);

    const createAllClasses = () => {
        if(newSemesterData.value.period)
        {
            newSemesterData.value.start_date = newSemesterData.value.period[0];
            newSemesterData.value.end_date = newSemesterData.value.period[1];
        }
        else
        {
            newSemesterData.value.start_date = null;
            newSemesterData.value.end_date = null;
        }

        if(!newSemesterData.value.name
              || newSemesterData.value.name.trim().length == 0
              || !newSemesterData.value.code
              || newSemesterData.value.code.trim().length == 0
              || !newSemesterData.value.start_date
              || !newSemesterData.value.end_date)
        {
            Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            return false;
        }

        copySemesterClasses(newSemesterData.value);
    };

    const copySemesterClasses = async (newSemesterData) => {
        ApiService.setHeader();
        ApiService.post("class/copy-semester-classes/"+semesterId.value, newSemesterData)
            .then(() => {
              Swal.fire({
                  text: "New classes are created successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  window.location.reload();
                });
            })
            .catch(({ response }) => {
                console.log('copyClasses error => '+response.data.errors);
                Swal.fire({
                  text: "Failed to create the new classes",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            });
    }

    return {
      createAllClasses,
      moment,
      newSemesterData
    };
  },
});
