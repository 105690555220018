1. Select copy from semester 
2. Enter tem info : Name, Code, Period(startDate/endDate)
3. Button to submit the class creation request.

<template>
    <div
      class="modal fade"
      id="modal_copy_classes"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Copy all classes</h2>
  
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              id="create_charge_close_button"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
  
          <div class="modal-body py-lg-10 px-lg-10">
              <div class="flex-row-fluid px-lg-15">
                <el-form
                  id="kt_modal_copy_classes_form"
                  class="form"
                  :model="formdata"
                  ref="formRef"
                >
                    <div class="w-100">
                      <div class="fv-row mb-10">
                        <div class="mb-10">
                          <label class="d-flex align-items-center fs-4 fw-bold mb-2">
                            <span>Copy From Semester</span>
                          </label>
                          <p style="font-size:16px;">{{ semesterName }}</p>
                        </div>
                        <div>
                            <div>
                                <label class="d-flex align-items-center fs-4 fw-bold mb-2">
                                    <span>New Semester</span>
                                </label>
                            </div>
                            <div>
                                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                                <span class="required">Name</span>
                                </label>
                                <el-form-item prop="semester_name">
                                <input
                                    type="text"
                                    name="newSemesterData.name"
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="newSemesterData.name"
                                />
                                </el-form-item>
                            </div>
                            <div>
                                <label
                                class="d-flex align-items-center fs-6 fw-bold mb-2"
                                >
                                <span class="required">Code</span>
                                </label>
                                <el-form-item prop="semester_code">
                                <input
                                    type="text"
                                    class="form-control form-control-lg form-control-solid"
                                    name="newSemesterData.code"
                                    v-model="newSemesterData.code"
                                />
                                </el-form-item>
                            </div>
                            <div>
                                <label
                                class="d-flex align-items-center fs-6 fw-bold mb-2"
                                >
                                <span class="required">Period</span>
                                </label>
                                <el-date-picker
                                v-model="newSemesterData.period"
                                type="daterange"
                                range-separator="To"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                class="w-100"
                                format="MM/DD/YYYY">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                  </div>
                <div class="d-flex flex-stack" style="float:right">
                  <div>
                    <button
                      type="button"
                      class="btn btn-lg btn-primary"
                      @click="createAllClasses()"
                      >
                      <span class="indicator-label">
                          Create all
                          <span class="svg-icon svg-icon-3 ms-2 me-0">
                          <inline-svg
                              src="media/icons/duotune/arrows/arr064.svg"
                          />
                          </span>
                      </span>
                    </button>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, toRefs, watch } from "vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import Swal from "sweetalert2";

export default defineComponent({
  name: "copy-classes-modal",
  props:{
    semesterId: Number,
    semesterName : String
  },
  setup(props) {
    const newSemesterData = ref({id: 0, name:'', code:'', period: [], start_date:null, end_date:null});
    const { semesterId } = toRefs(props);

    const createAllClasses = () => {
        if(newSemesterData.value.period)
        {
            newSemesterData.value.start_date = newSemesterData.value.period[0];
            newSemesterData.value.end_date = newSemesterData.value.period[1];
        }
        else
        {
            newSemesterData.value.start_date = null;
            newSemesterData.value.end_date = null;
        }

        if(!newSemesterData.value.name
              || newSemesterData.value.name.trim().length == 0
              || !newSemesterData.value.code
              || newSemesterData.value.code.trim().length == 0
              || !newSemesterData.value.start_date
              || !newSemesterData.value.end_date)
        {
            Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            return false;
        }

        copySemesterClasses(newSemesterData.value);
    };

    const copySemesterClasses = async (newSemesterData) => {
        ApiService.setHeader();
        ApiService.post("class/copy-semester-classes/"+semesterId.value, newSemesterData)
            .then(() => {
              Swal.fire({
                  text: "New classes are created successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  window.location.reload();
                });
            })
            .catch(({ response }) => {
                console.log('copyClasses error => '+response.data.errors);
                Swal.fire({
                  text: "Failed to create the new classes",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            });
    }

    return {
      createAllClasses,
      moment,
      newSemesterData
    };
  },
});
</script>