
import { defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CreateClass from "@/views/academics/modals/CreateClassModal.vue";
import EditClass from "@/views/academics/modals/EditClassModal.vue";
import CreateCharge from "@/views/academics/modals/CreateChargeModal.vue";
import CopyClasses from "@/views/academics/modals/CopyClassesModal.vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { ElMessageBox } from "element-plus";
import router from "@/router";

export default defineComponent({
  name: "account-overview",
  components: {
    CreateClass,
    EditClass,
    CreateCharge,
    CopyClasses
  },
  setup() {
    const semesters = ref([] as any);
    const classes = reactive([] as any);
    const selectedSemester = ref({} as any);
    const selectedClassData = ref({} as any);
    const selectedClassType = ref('');
    const instructors = ref<any>([]);
    const selectedSemesterId = ref(0);
    const selectedSemesterName = ref('');
    const daysOfWeek = [{label :'Sun', value : 0}, 
                        {label :'Mon', value : 1},
                        {label :'Tue', value : 2},
                        {label :'Wed', value : 3},
                        {label :'Thu', value : 4},
                        {label :'Fri', value : 5},
                        {label :'Sat', value : 6}];

    const getSemesters = () : any => {
        return new Promise((resolve, reject) => {            
            ApiService.setHeader();
            ApiService.get("class/semesters")
                .then(({ data }) => {
                    let sortedData = data.data.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });
                    semesters.value = sortedData;
                    resolve(sortedData);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const updateSemester = async() => {
      getSemesters().then((data) => {
        if(data){
          let currentSemester = data.filter(semester => semester.id == selectedSemesterId.value)[0];
          if(!currentSemester){
            let today = new Date();
            let currentSemester = data.filter(semester => (semester.start_date <= today && semester.end_date >= today));

            if(currentSemester.length > 0){
              selectedSemester.value = currentSemester[0];
            }
            else{
              selectedSemester.value = data[0];
            }
            
            selectedSemesterId.value = selectedSemester.value.id;
          }
          else{
            selectedSemester.value = currentSemester;
          }

          selectedSemesterName.value = getSelectedSemesterName(selectedSemester.value);

          getClasses();
          getInstructors();
        }
      });
    }

    const getClasses = async() => {
        await new Promise((resolve, reject) => { 
            ApiService.setHeader();   
            ApiService.get("class/"+selectedSemester.value.id)
                .then(({ data }) => {
                  data.data.forEach(program => {
                    program.isDeletable = program.levels  == null || program.levels.length == 0;
                    program.levels.forEach(level => {
                      level.isDeletable = level.sessions  == null || level.sessions.length == 0;
                      level.sessions.forEach(session => {
                        session.isDeletable = session.subjects == null || session.subjects.length == 0;
                        session.subjects.forEach(subject =>{
                          subject.isDeletable = true;
                        })
                      })
                    })
                  });
                  classes.values= data.data;
                  resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const handleEdit = (type, data) => {
        selectedClassData.value = data;
        selectedClassType.value = type;
        let editButton = document.getElementById('class_edit_button');
        editButton?.click();
    };

    const handleDelete = (data) => {
      ElMessageBox.confirm('Are you sure to delete this class?')
            .then(() => {
                let classType = data.semester_id != undefined ? 'program' : data.program_id != undefined ? 'level' : data.level_id != undefined ? 'session' : 'subject';   
                ApiService.setHeader();
                ApiService.delete("class/"+classType+"/"+data.id)
                  .then(() => {
                      getClasses(); 
                  })
                  .catch(({ response }) => {
                      console.log('handleDelete error => '+response.data.errors);
                  });
            });
    };

    const handleClick = (el) =>{
        el.target.parentElement?.querySelector(".nested")?.classList.toggle("active");
        el.target.classList.toggle("caret-down");
    }

    const linkSubjectPage = (subject_id) => {
        router.push({ name: "subject", params: { id: subject_id } });
    }

    const getInstructors = async() =>{
      await new Promise((resolve, reject) => {  
        ApiService.setHeader();
        ApiService.get("staff")
              .then(({ data }) => {
                  instructors.value = data.data;
                  resolve(data);
              })
              .catch(({ response }) => {
                console.log('getAdvisors error => '+response.data.errors);
                reject(response.data.errors);
              });
      });
    }

    const getSelectedSemesterName = (semester) => {
      return semester.name+'/'+semester.code+'/'+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')
    }

    onBeforeMount(async() => {
      updateSemester();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Class", ["Academics"]);
    });

    return {
      handleEdit,
      handleDelete,
      classes,
      selectedSemester,
      semesters,
      moment,
      handleClick,
      selectedClassData,
      selectedClassType,
      getClasses,
      linkSubjectPage,
      instructors,
      daysOfWeek,
      selectedSemesterId,
      updateSemester,
      selectedSemesterName
    };
  },
});
